
@media (min-width: 1300px){
  .container{max-width: 1280px;}
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  float: left;
}
.clearfix{
  clear:both;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}
a{
  text-decoration:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
a:hover{
  text-decoration:none;
  outline:none;
}
input,button,select,textarea{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
}
ul,li{
  list-style:none;
  padding:0px;
  margin:0px; 
}
input{
  transition: all 500ms ease;
}
button:focus,
input:focus,
textarea:focus{
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}
p{
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #444444;
  font-family: 'Lato', sans-serif;
  margin-bottom: 0px;
  font-style: normal;
  transition: all 500ms ease;
}
h1,h2,h3,h4,h5,h6{
  position:relative;
  font-family: "Lato",sans-serif;
  font-weight: 400;
  color: #161616;
  margin: 0px;
  transition: all 500ms ease;
}
body{
  font-family: 'Lato', sans-serif;
  background-color: white;
  overflow-x: hidden;
  z-index: 0;
}
/* body::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
  width: 7px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
  background-color: #795548;
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
} */

/* --------------------- Menu Section ------------------------------ */
.App{
  position: relative;
  width: 100%;
  float: left;
  background-color: white;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  z-index: 90;
}
.top_header_area {
  padding: 4px 0px;
  float: left;
  width: 100%;
}
.top_header_area li {
  display: inline;
  font-size: 17px;
  color: white;
}
.top_header_area li.qqq {
    display: inline;
    font-size: 16px;
    color: #444444;
    text-shadow: 0 0 1px #fff;
    font-weight: 400;
    letter-spacing: 1px;
    margin-right: 20px;
}
.top_header_area .menu-item a {
  float: right;
  color: #000000;
}
.top_header_area2 {
  float: left;
  width: 100%;
  position: relative;
  padding: 17px 20px;
}
.header-middle{
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
}
.outer-box {
  width: 100%;
}
.header-middle img {
  margin: 0 auto 5px;
  display: block;
}
ul.main-menu>li.menu-item>a {
margin-left: 10px;
margin-right: 10px;
padding-top: 25px;
padding-bottom: 25px;
font-size: 17px;
text-transform: capitalize;
font-weight: 500;
color: #000000;
letter-spacing: 1px;
}
.menu-item {
  list-style: none;
}
.menu-item a {
  color: #ffffff;
  font-size: 17px;
}
.menu-item a:hover{
  color: #ff1100;
}
.is-active {
  color: #ff1100!important;
}
.cols {
  color: #0ca9f3;
}
ul.main-menu>li.menu-item .aaa2{
  color: #0082ff;
  font-weight: 700;
}
@media (min-width: 992px){
 ul.main-menu {
    display: flex;
    position: relative;
    list-style: none;
    padding: 0;
    float: none;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
  }
  ul.main-menu>li.menu-item:first-child { padding-left: 0;}
  ul.main-menu>li.menu-item>a:last-child {margin-right: 0; }
  ul.main-menu>li.menu-item>a { margin-left: 13px;margin-right: 13px;}
  ul.main-menu>li .sub-menu { padding: 30px;  }
  ul.main-menu>li .sub-menu {
    width: 227px;
    position: absolute;
    top: 60%;
    left: 0;
    background: #ffffff;
    margin-top: 17px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 1px 8px 13px #c5c5c5;
     }
     ul.main-menu>li .sub-menu .menu-item a {
      color: #000000;
      font-size: 17px;
      text-transform: capitalize;
      margin-bottom: 17px;
      float: left;
     }
     ul.main-menu>li .sub-menu .menu-item a:hover {
      color: #ff1100;
     }
     ul.main-menu>li .sub-menu li {
         margin-bottom: 15px;
         color: #333;
         text-transform: capitalize;
         font-weight: 400;
         font-size: 14px;
         margin-bottom: 10px;
     }
     ul.main-menu .has-children:hover>.sub-menu { pointer-events: visible;  opacity: 1; }
     ul.main-menu .has-children:hover {z-index: 200;}
ul.main-menu .has-children {position: relative;}
}  
@media (max-width: 991px){ 
.menuq1{display:none;}
.menuq2{ display:block!important;}
.res5c {
  display: table!important;
  float: none;
  background-color: #ff1100;
  color: #fff;
  padding: 7px;
  border-radius: 5px;
  margin: 10px auto;
  cursor: pointer;
}
}
.has-children .svg-inline--fa.fa-w-14 {
    width: 10px;
    margin-left: 3px;
    vertical-align: -6px;
    color: #403d3d;
}
@media (min-width:992px) and (max-width:1200px){
  ul.main-menu>li.menu-item>a {margin-left: 10px!important;}
}
/* --------------------- slider Section ------------------------------ */
.Toastify__toast {
  position: relative;
  min-height: 107px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 17px;
  border-radius: 7px;
  outline: 2px solid white;
  outline-offset: -10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.topslider{
  /* background-image: url('./Img/bg-choose-bike.jpg'); */
  background-repeat: no-repeat;
  padding: 60px 0 60px 0;
  background-position: center;
  position: relative;
}
.topslider img{
  box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
  color: #000;
  border-radius: 7px;
}
.topslider h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 47px;
} 
.topslider ul{
  padding-top: 37px;
  padding-bottom: 37px;
}
.topslider .BrainhubCarouselItem{
  padding-top: 37px;
  padding-bottom: 37px;
}
.menu-item .svg-inline--fa.fa-w-16 {
  color: #ff1100;
}

.videosss2 {
  width: 100%;
  height: 190px;
  margin: 17px auto 17px;
  display: block;
  position: relative;
  box-shadow: 2px -11px 17px -15px, 0px 17px 7px -17px;
  color: #8a8d95;
  border: 7px solid white;
  border-radius: 10px 10px 0 0;
}
.topslider62videos{
  padding-left: 0px;
  padding-right: 37px;
}
.topslider66 {
  position: relative;
  padding: 0px 0px 47px 0px;
  float: left;
  width: 100%;
}
.topslider66 .shape_2 {
  top: 14%;
}
.Video__home h1, .Video__home h6{
  margin-bottom: 0px!important;
}
.topslider66 h1 {
  color: #5A3733;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 40px;
  margin-bottom: 37px;
}
.coverDesign6 img {
  height: 210px;
  margin: 27px auto 27px;
  display: block;
  border-radius: 4%;
  box-shadow: -1px 5px 13px #666464b0;
  border: 2px solid #ffffff;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.BrainhubCarousel__arrows {
  background-color: #ff1100!important;
}
.BrainhubCarousel__arrows:disabled {
  background-color: #ccc!important;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.media-container:hover .book {
  -webkit-transform: rotateX(5deg) rotateY(-50deg);
  transform: rotateX(5deg) rotateY(-50deg);
}
.app {
  --thickness: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-perspective: 1500px;
  perspective: 1500px;
}
.app[data-current-media="book"] .book-wrapper {
  display: block;
}
.book {
  position: relative;
  z-index: 1;
  max-width: 237px;
  max-height: 367px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  transform: rotateX(0deg) rotateY(0deg);
}
.book__front {
  border-radius: 5px;
  overflow: hidden;
  -webkit-transform: translateZ(calc(var(--thickness)));
  transform: translateZ(calc(var(--thickness)));
  width: 100%;
  float: left;
}
.news-block-one.ttt .media-container:hover .book {
  transform: rotateX(5deg) rotateY(-17deg);
}
.leqsss2 {
  height: 277px;
  margin: 47px auto 37px;
  display: block;
  border-radius: 4%;
  box-shadow: hsl(0, 0%, 80%) 0 0 16px;
}
.book__paper {
  height: 98%;
  width: calc(var(--thickness) * 2);
  background: white;
  position: absolute;
  top: 1%;
  right: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.3)), to(rgba(255, 255, 255, 0))), repeating-linear-gradient(90deg, white, #e2e2e2 1px, white 3px, #9a9a9a 1px);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0)), repeating-linear-gradient(90deg, white, #e2e2e2 1px, white 3px, #9a9a9a 1px);
  -webkit-transform: rotateY(90deg) translateX(calc(var(--thickness) / 7)) translateZ(calc(var(--thickness) / 1.2));
  transform: rotateY(90deg) translateX(calc(var(--thickness) / 7)) translateZ(calc(var(--thickness) / 1.2));
}
.book__back {
  width: 100%;
  height: 99.4%;
  position: absolute;
  top: 0;
  background: #356143;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transform: translateZ(calc(-1 * var(--thickness)));
  transform: translateZ(calc(-1 * var(--thickness)));
}
.book-shadow {
  position: absolute;
  width: 167px;
  height: calc(33px + var(--thickness));
  background: radial-gradient(70% 85%, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
  -webkit-transform: rotateX(90deg) rotateZ(30deg);
  transform: rotateX(90deg) rotateZ(30deg);
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  -webkit-filter: blur(32px);
  filter: blur(32px);
}
.book-wrapper img {
  max-width: 217px;
  width: auto;
  height: 302px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  margin: 0 auto;
  display: block;
}

.ttt h2{
  margin-bottom: 0!important;
}
.book-wrappertitlss2 {
  font-size: 18px;
  color: #fd6500;
  margin: 10px 0 0 0;
}
.book__front:before {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: url('./Img/sss2.png') repeat-y 0;
  background-size: 100% auto;
  opacity: 0.6;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.aaa3 {
  position: relative;
  box-shadow: 0 3px 24px rgb(246 249 253);
  border-radius: 7px;
  padding: 37px 37px 12px 37px;
  background-color: #fff;
  border: 1px solid #f0f2f3;
  margin-bottom: 27px;
}
.booksrec2 img{
  margin: 17px auto;
  display: block;
  transition: .3s;
}
.aaa3 .booksrec2:hover img{
  transform: scale(1.1) rotate(0deg);
}
.aaa3 h6 {
  font-family: 'Signika', serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.aaa3 .desc {
  max-height: 207px;
  margin-bottom: 17px;
  text-align: center;
  overflow: hidden;
}
.consssqqq{
    width: 100%;
    float: left;
}
.text_shadowbook2w {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #eeeef0;
  z-index: 0;
  left: 0;
  width: 161px;
  height: 100%;
  background-color: #f6f6f6;
}
.text_shadowbook2sw {
  position: absolute;
  top: 28%;
  transform: translateY(-24%);
  color: #eeeef0;
  z-index: 0;
  left: 60px;
  width: 161px;
  background-color: #ffffff;
  height: 88%;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.aaa img {
  width: 53px;
  height: 77px;
  border-radius: 3px;
  box-shadow: 0 7px 7px -5px, 0 0 7px -6px;
  color: #a9a1a1;
  margin: 0 auto;
  display: block;
}
.aaa2{
  max-height: 60px;
  overflow: hidden;
}
.topslider .aaa .BrainhubCarouselItem {
  padding-top: 0;
}
.clearfix{
  clear:both;
}
.coverDesign img{
  height: 317px;
  margin: 97px auto 0px;
  display: block;
  border-radius: 7%;
  box-shadow: -1px 5px 13px #666464;
}
.text_shadow {
  font-size: 777px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #eeeef0;
  font-family: "Montserrat", sans-serif;
  z-index: 0;
  left: 0px;
  width: 161px;
  background-color: #ffffff;
}
.bosss h2 {
  color: #333333;
  text-align: left;
  overflow: hidden;
  font-size: 30px;
  line-height: 47px;
  font-weight: 600;
}
.bosss p {
  font-size: 18px;
  color: black;
  margin-bottom: 7px;
  line-height: 37px;
}
.bosss p span.websss{
  color: #0ca9f3;
  cursor: pointer;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.desc2 {
  height: 157px;
  overflow: hidden;
  font-size: 18px;
  color: #666666;
  font-weight: 400;
  font-family: "Muli", sans-serif;
  margin: 20px 0px 17px;
  line-height: 37px;
  margin-bottom: 27px;
}
.desc2 p, .desc2 span {
  background-color: rgb(255 255 255/0)!important;
  text-align: left!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.desc2mmm {
  height: 187px;
  overflow: hidden;
  font-size: 18px;
  color: #666666;
  font-weight: 400;
  font-family: "Muli", sans-serif;
  margin: 0px 0px 17px;
  line-height: 37px;
  margin-bottom: 7px;
}
.desc2mmm p, .desc2mmm span {
  background-color: rgb(255 255 255/0)!important;
  text-align: center!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.base-btn1{
  background: #795548;
  color: #fff;
  font-size: 14px;
  padding: 0 42px;
  font: 600 17px/55px "Montserrat", sans-serif;
  color: #fff;
  text-align: center;
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all 0.3s linear;
  letter-spacing: 0.25px;
  z-index: 1;
  cursor: pointer;
}
.desc2 h1, .desc2 h2, .desc2 h3, .desc2 h4, .desc2 h5, .desc2 h6{
    position: relative;
    font-size: 18px;
    line-height: 37px;
    color: #444444;
    font-family: 'Lato', sans-serif;
    margin-bottom: 0px;
    font-style: normal;
}
.bann {
  font-size: 17px;
  color: #ff0000!important;
  text-align: left;
  margin-top: 13px;
  font-weight: 600;
  margin-bottom: 17px;
  line-height: 27px;
}
 .round {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: auto;
  max-width: 100%;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.topslider62 {
  position: relative;
  padding: 37px 0px 17px 0px;
  float: left;
  width: 100%;
}
.topslider62 h1 {
  color: #5A3733;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
}
.coverDesign2 img {
  height: 277px;
  margin: 37px auto 77px;
  display: block;
  border-radius: 4%;
  box-shadow: hsl(0, 0%, 80%) 0 0 16px;
}
.base-btn2 {
  white-space: nowrap;
  position: absolute;
  right: 12%;
  bottom: 3%;
}
.text_shadow2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #eeeef0;
  z-index: 0;
  right: 0px;
  width: 161px;
  background-color: #f6f6f6;
  height: 100%;
}
.text_shadow2s {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #eeeef0;
  z-index: 0;
  right: 59px;
  width: 161px;
  background-color: #ffffff;
  height: 73%;
}
.topslider63 {
  position: relative;
  padding: 60px 0px 47px 0px;
  float: left;
  width: 100%;
  overflow: hidden;
  background-color: #EDF4FA;
}
.topslider63 h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
}
.coverDesign3 img {
  height: 257px;
  margin: 0px auto 0px;
  display: block;
  border-radius: 7%;
  box-shadow: -1px 5px 13px #666464;
}
.desc2 span{
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 18px!important;
  color: #666666!important;
  font-weight: 400;
  font-family: "Muli", sans-serif!important;
  margin: 16px 0px 45px;
  line-height: 37px;
}
.text_shadow3 {
  font-size: 977px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Montserrat", sans-serif;
  color: #f6f6f6;
  z-index: 0;
  left: 0px;
  width: 155px;
  background-color: #ffffff;
}
.topslider64 {
  position: relative;
  padding: 60px 0px 37px 0px;
  float: left;
  width: 100%;
}
.topslider64 h1 {
  color: #5A3733;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 17px;
}
.topslider64 h6{
  font-size: 20px;
  line-height: 37px;
  font-weight: 400;
  letter-spacing: 0.7px;
  padding: 0 17px 30px 0px;
}
.base-btn4 {
  white-space: nowrap;
  position: absolute;
  right: 15%;
  bottom: -23%;
}
.base-btn4s{
  white-space: nowrap;
  background: linear-gradient(180deg,#f35c15 0,#f99641 0,#f35c15 99%);
  font-size: 14px;
  padding: 0 42px;
  font: 600 15px/55px "Montserrat", sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all 0.3s linear;
  letter-spacing: 0.25px;
  z-index: 1;
  cursor: pointer;
}

.topslider65 {
  position: relative;
  padding: 67px 0px 67px 0px;
  float: left;
  width: 100%;
  overflow: hidden;
  background-image: url(https://writers.support/uploads/slidesss.jpg);
  background-color: #eaf2f5;
  background-repeat: no-repeat;
}
.topslider65 h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
}
.coverDesign5 img {
  height: 197px;
  margin: 13px auto 27px;
  display: block;
  border-radius: 7%;
  box-shadow: -1px 5px 13px #666464;
  border: 4px solid white;
}
.text_shadow5 {
  font-size: 773px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Montserrat", sans-serif;
  color: #f6f6f6;
  z-index: 0;
  left: 0px;
  width: 155px;
  background-color: #ffffff;
}
.FGFUF {
  background: rgba(255, 255, 255, .8);
  border-radius: 8px;
  padding: 64px;
  text-align: left;
  height: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.FGFUF:before {
  content: '';
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border: 1px dashed hsl(87, 58%, 45%);
  position: absolute;
  top: 12px;
  left: 12px;
}
.rwt__tabs[data-rwt-vertical="true"] {
  position: relative;
  width: 100%;
}
#vertical-tab-one{
  width:100%;
}
.rwt__tab {
  background-color: white!important;
  margin: 0px 0px 30px 0px;
  font-size: 18px!important;
  font-weight: 700;
  border-radius: 7px;
  overflow: hidden;
  padding: 12px 37px 12px 37px!important;
}
.rwt__tablist[aria-orientation="vertical"] {
  border-right: 0px solid #ddd!important;
  margin-right: 4rem!important;
}
.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"] {
color:#f66d26!important;
}
.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  border-right: 3px solid #f66d26!important;
}
.coverDesign2tab img {
  height: 247px;
  max-width: 270px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  margin-bottom: 27px;
  border-radius: 16px 16px 16px 16px;
  border: 0 solid #e6e6fa;
  box-shadow: 1px 30px 60px 8px rgb(70 72 228 / 7%);
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
}
.taaa {
  border-radius: 8px;
  padding: 10px;
  text-align: left;
  height: 100%;
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  float: left;
}
.dythjkyuf {
  position: relative;
  width: 100%;
  float: left;
  margin: 20px 0 33px 0;
  padding: 17px;
  background-color: #f7f9fd;
  border-radius: 7px;
}
.dythjkyuf2 {
  box-shadow: 0px 1px 11px #928f8f;
  float: left;
  width: 117px;
  height: 117px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  margin: 0 auto!important;
  display: block;
}
.usernamesss2 {
  margin-left: 37px;
  float: left;
  margin-top: 27px;
  font-weight: 900;
  font-family: "Signika",serif;
  color: #795548;
}
.leqssswww {
  position: relative;
  margin-top: 37px;
}
.leqssswww img {
  height: 277px;
  margin: 27px auto;
  display: block;
  border-radius: 4%;
  box-shadow: hsl(220deg 45% 97%) 0 0 16px;
  overflow: hidden;
  width: 100%;
  object-fit: contain;
}
 .shape_1 {
  left: 50%;
  bottom: 135px;
  width: 570px;
  height: 570px;
  background: linear-gradient(to right, #EDF2F8, #CCD4E1);
  border-radius: 50%;
  transform: translateX(-50%);
}
.shape_2 {
  left: 18%;
  top: 28%;
  width: 100px;
  height: 100px;
  background: linear-gradient(to right, #f8fbff, #edeef2);
  border-radius: 50%;
}
.shape_3 {
  left: 42%;
  bottom: -60px;
  width: 160px;
  height: 160px;
  background: linear-gradient(to right, #f8fbff, #edeef2);
  border-radius: 50%;
}
.shape_4 {
  right: 25%;
  top: 28px;
  width: 100px;
  height: 100px;
  background: linear-gradient(to right, #f8fbff, #edeef2);
  border-radius: 50%;
}
.shape_5 {
  left: 55%;
  bottom: 30%;
  width: 20px;
  height: 20px;
  background: #fc6a1f;
  border-radius: 50%;
  opacity: .4;
}
.position-absolute {
  position: absolute!important;
}
.d-block {
  display: block!important;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- *//* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.topslider6 {
  position: relative;
  background-position: center;
  width: 100%;
  float: left;
}
.topslider6.Recommended__homes h1 {
  padding:57px 0 17px 0;
}
.topslider6.Recommended__homes .aaalasssqqq2 {
  padding: 17px 0px 7px 15px;
}
.aaa4 {
  position: relative;
  border-radius: 7px;
  padding: 27px 17px 37px 17px;
  background-color: #fff;
  margin-bottom: 37px;
  width: 100%;
  float: left;
  border: 0 solid #e6e6fa;
  box-shadow: inset 1px 30px 60px 8px #b5e6fd;
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
  padding: 27px 17px;
  background: linear-gradient(to right, #ffffff, #edf9ff);
}
/* @media (min-width: 992px) { 
.topslider6 .col-md-3 {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
}
} */
.topslider6 h1 {
  color: #5A3733;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
} 
.topslider6 .book {
  margin-bottom: 7px
}

.topslider6 .book-wrapper2 img {
  box-shadow: -1px 1px 10px #807f7f;
  margin: 10px auto 17px;
  display: block;
  max-width: 207px;
  height: 277px;
  transition: .3s;
  border-radius: 10px;
}
.aaa4 .book-wrapper2:hover img{
  transform: scale(1.1) rotate(0deg);
}
.aaa4 .book-shadow {
  width: 97px;
  height: calc(13px + var(--thickness));
}
.aaa4 .arrow-read-more {
  position: absolute;
  right: 17px;
  bottom: 7px;
  transform: translateX(0);
}
.aaa4 .arrow-read-more-2{
  right: 17px;
  bottom: 7px;
}

.aaa4:hover .arrow-read-more-2 {
  transform: translateX(0);
  opacity: 1;
  color: #2b2d42;
}
.aaa4:hover .arrow-read-more {
  opacity: 0;
  transform: translateX(100%);
}
.aaa4 h4 {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin: 7px auto 10px;
  display: block;
  font-weight: 600;
  overflow: hidden;
  font-family: "Lato",sans-serif;
  color: #000000;
}
.aaa4 .app:hover h4{
  color: #ff1100;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.Appfooter {
  background-color: #f5f7fb;
  padding-top: 47px;
  padding-bottom: 47px;
  position: relative;
  clear: both;
  z-index: 0;
  background: linear-gradient(to right, #f8fbff, #edeef2);
}
.Appfooter ul.main-menu>li.menu-item>a {
  font-size: 20px;
  color: #ffffff;
}
.copyright-block {
  position: relative;
  background-color: #F8F7F1;
  color: #5A3733;
  text-align: left;
  padding: 27px 0 22px;
}
@media (max-width: 768px) {
  .copyright-block {
    text-align: center;
  }
  .copyright-block p {
    font-size: 14px;
  }
}
@media (max-width: 479px) {
  .copyright-block p {
    font-size: 14px;
  }
}
.copyright-block div {
  font-size: 12px;
}
.copyright-block p {
  margin-bottom: 0;
  display: inline-block;
}
.menu-itemcopyright {
  font-size: 17px;
  position: absolute;
  right: 27px;
  top: 47px;
}
.menu-itemcopyright a{
  color: #020202!important;
}
.copyright-block a {
  color: #ff1100;
}
.copyright-block a:hover {
  color: #0078de;
}
.copyright-block .container {
  position: relative;
}
.copyright-block.copyright-layout-copyright {
  background-color: #5A3733;
  color: #fff;
  text-align: center;
}
.copyright-block.copyright-layout-simple {
  text-align: center;
  padding: 0px 0 0px;
  background-color: transparent;
  background-repeat: repeat-x;
  position: relative;
  top: -14px;
}
.copyright-block div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 7px 0 0 0;
}
.copyright-block.copyright-layout-simple .logo-footer {
    text-align: left;
}
.copyright-block.copyright-layout-simple .logo-footer p {
  margin-top: 17px;
}
@media (max-width: 991px) {
  .copyright-block.copyright-layout-simple .logo-footer {
    text-align: center;
    margin: 0 auto;
    display: block;
  }
  .copyright-block.copyright-layout-simple .logo-footer p {
    margin-bottom: 17px;
}
  .copyright-block.copyright-layout-simple img {
    float: none;
}
}
.copyright-block.copyright-layout-simple .go-top {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.copyright-block.copyright-layout-copyright_waves {
  text-align: center;
  background-color: transparent;
  background-image: url('./Img/footer-white-waves.png');
  background-repeat: repeat-x;
  position: relative;
  margin-top: -100px;
  display: inline-block;
  width: 100%;
  padding-top: 14px;
}
.copyright-block.copyright-layout-copyright_waves .container {
  width: 100% !important;
  background-color: #fff;
  background-color: var(--white);
  padding: 47px 0 22px;
}
.copyright-block.copyright-layout-copyright_waves .go-top {
  display: none;
}
.copyright-block .ltx-social .header {
  display: none;
}
.copyright-block .ltx-social ul {
  list-style: none;
  padding: 0;
  margin-bottom: 35px;
}
.copyright-block .ltx-social ul li {
  display: inline-block;
}
.copyright-block .ltx-social ul li a {
  line-height: 52px;
  width: 50px;
  height: 50px;
  background-color: #FFD832;
  background-color: var(--second);
  color: #EB6D2F;
  color: var(--main);
  text-align: center;
  display: block;
  font-size: 18px;
  margin: 0 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.copyright-block .ltx-social ul li a:hover {
  background-color: #5A3733;
  background-color: var(--black);
  color: #fff;
  color: var(--white);
}
.copyright-block .go-top {
  position: absolute;
  right: 0px;
  top: -12px;
  color: #5A3733;
  color: var(--black);
  text-align: center;
  font-size: 18px;
  display: block;
  width: 46px;
  height: 46px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: 'Signika', serif;
  font-family: var(--font-headers), serif;
  font-weight: 900;
  text-transform: uppercase;
}
.copyright-block .go-top.go-top-img {
  padding: 0;
}
@media (max-width: 768px) {
  .copyright-block .go-top {
    display: none;
  }
}
.copyright-block .go-top .fa {
  display: block;
  font-size: 18px;
  font-weight: bold;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: -4px;
  z-index: 11;
  color: #fff;
  color: var(--white);
}
.copyright-block .go-top .fa:before {
  content: "\f106";
}
.copyright-block .go-top .ltx-hex {
  top: 0px;
  left: 0;
  position: absolute;
  z-index: 10;
  border-radius: 4px;
  width: 46px;
  height: 39.836px;
}
.copyright-block .go-top .ltx-hex .ltx-hex-inner:before {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.copyright-block .go-top:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.copyright-block .go-top:hover .ltx-hex-inner:before {
  background-color: #0ca9f3;
  background-color: var(--black);
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.scrolls {
  background-color: #0ca9f3;
  color: white;
  width: 37px;
  height: 37px;
  line-height: 30px;
  border-radius: 100%;
  font-size: 20px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
.scrolls:hover {
  background-color: #ff1100;
}
.scrolls .svg-inline--fa.fa-w-14 {
  width: 16px;
  margin: 8px auto;
  display: block;
  vertical-align: middle;
  line-height: 37px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.breadcrumbs {
  background-repeat: repeat;
  background-position: top center;
  background-size: contain;
  position: relative;
}
.breadcrumbs .content-box h1 {
  position: relative;
  color: #0ca9f3;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 37px;
}
.breadcrumbs .content-box .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: #000000;
  padding-right: 22px;
  margin-right: 6px;
}
.breadcrumbs .content-box .bread-crumb li:before {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 9px;
  right: 4px;
  background: #ff1100;
}
.breadcrumbs .content-box .bread-crumb li a {
  color: black;
}
.breadcrumbs ul{
  margin: 0 auto;
  display: table;
}
.breadcrumbs .content-box .bread-crumb li:last-child {
  padding: 0px;
  margin: 0px;
}
.breadcrumbs .content-box .bread-crumb li:last-child:before {
  display: none;
}
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.login-1 {
  padding: 60px 0 0px 0;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
.login-1 .text_shadow3 {
  font-size: 1077px;
  top: 50%;
  color: #ffffff;
  z-index: 0;
  left: 0px;
  width: 155px;
  background-color: #f6f6f6;
}
.login-forms h1 {
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 17px;
  font-size: 36px;
  line-height: 47px;
  color: #ff1100;
  font-weight: 700;
}
.login-forms p {
  text-align: left;
  float: left;
  display: block;
  width: 100%;
  margin-bottom: 17px;
}
.login-1 h6 {
  cursor: pointer;
  clear: both;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 17px;
  text-align: center;
  color: #403c3c;
  font-family: 'Lato', sans-serif;
}
.login-1 h6:hover {
  color: rgb(12, 169, 243);
}
.logsss3 {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-clip: border-box;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);
  border: none;
  border-radius: 3px;
  padding: 60px 60px 60px 60px;
  height: -moz-fit-content;
  background-size: cover;
  margin-top: 27px;
  margin-bottom: 137px;
}
.login-1 .aaa4 {
  background-color: #fff0;
}
.login-1 .form-group label {
  font-size: 16px;
}
.login-1 .form-group {
  margin-bottom: 27px;
}
.thm-btn {
  background-color: #0ca9f3;
  background-image: -webkit-linear-gradient(
45deg, #53c5fb 15%, #0b9ce1 65%);
  padding: 7px 17px 10px 17px;
  font-size: 18px;
  color: white;
  border-radius: 7px;
  margin-right: 17px;
}
.thm-btn:hover {
  background-image: -webkit-linear-gradient(45deg, #f87070 15%, #fb6a1f 65%);
}
.login-1 .svg-inline--fa.fa-w-16 {
  width: 20px;
  height: 17px;
}
.form-control {
  height: calc(2.5em + .75rem + 7px);
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.paymentpendings{
  padding: 67px 0 67px 0;
}
.paymentpendings .breadcrumbs .content-box h1 {
  margin-bottom: 57px;
  font-size: 37px;
  text-align: center;
}
.basccsss {
  padding: 0;
  height: 347px;
  overflow: hidden;
  border-bottom: 1px solid #e6e6fa;
  margin-bottom: 47px;
}
.basccsss img {
  height: 187px;
  width: auto;
  overflow: hidden;
  border-radius: .5vmin;
  box-shadow: -1px 1px 10px #807f7f;
  margin: 0 auto 17px;
  display: block;
  max-width: 100%;
}
.basccsss h3 {
  color: #000000;
  font-size: 20px;
  line-height: 27px;
  font-family: 'Signika', serif;
  margin-bottom: auto;
  height: 30px;
  overflow: hidden;
  text-align: center;
}
.btn-primary5 {
  color: #fff;
  background-color: #0ca9f3;
  border-color: #0ca9f3;
  font-size: 20px;
  padding: 10px 17px 10px 17px;
  box-shadow: 0px 7px 10px #a09e9e;
  border-radius: 7px;
  font-weight: 700;
  margin: 10px auto;
  display: table;
}
.basccsss2 h4{
  text-align: center;
  margin-top:37px;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.awrawsss{
  position: relative;
    color: #ff1100;
    line-height: 1.2em;
    font-weight: 900;
    font-family: 'Signika', serif;
    margin-bottom: 17px;
    font-size: 37px;
    margin-top: 37px;
}
.members{
  padding: 67px 0 67px 0;
  float: left;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 87px;
  position: relative;
}
.members .text_shadowbook2 {
  background-color: #f0f0f0;
}
.members .text_shadowbook2s {
  background-color: #ffffff;
  position: absolute;
  top: 27%;
  transform: translateY(-24%);
  color: #eeeef0;
  z-index: 0;
  left: 60px;
  width: 161px;
  height: 90%;
}
.memberstabsss {
  margin: 0 auto;
  display: table;
}
.memberstabsss li:hover {
  color: #ffffff!important;
  background-color: #ff1100;
}
.memberstabsss li {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 14px 10px 14px 10px;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
  border: 1px solid #fbf8f8;
  margin: 0 7px 0 7px;
  border-radius: 4px;
  line-height: normal;
}
.memberstabsss li svg{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25px;
  flex: 0 0 25px;
  max-width: 25px;
  width: 25px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
}
.memberstabsss .active {
  color: #ffffff!important;
  background-color: #ff1100;
}
.card {
  display: -ms-flexbox;
  display: flow-root;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff75;
  background-clip: border-box;
  border-radius: 3px;
  min-height: 100vh;
  border: 1px solid #dcdce4;
  box-shadow: 1px 3px 7px #ded8d8;
  padding: 27px;
}
.btn-primary4 {
  color: #fff;
  background-color: #676767;
  border-color: #656565;
  margin-top: 0px;
  margin-left: 17px;
  font-size: 17px;
  padding: 10px 17px 10px 17px;
  box-shadow: 0px 7px 10px #a09e9e;
  border-radius: 10px;
}
.iconsss2sss {
  width: 17px;
  color: black;
  margin-right: 7px;
  vertical-align: middle;
}
.myaccount-content span{
  float: right;
  font-size: 23px;
  color: #ff1100;
}
.members .breadcrumbs h1{
  text-align: center;
}
/* ------------------------------------------------------------------------- */
.aaa6{
  margin-top: 37px;
}
/* ------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.profileimgsss {
  width: 137px;
  max-width: 137px;
  max-height: 137px;
  min-width: 137px;
  min-height: 137px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  height: 167px;
  border-radius: 14%;
  box-shadow: 0px 3px 17px 0px #babec3;
}
.profilepageleftsss h2 {
  position: relative;
  color: #000000;
  line-height: 1.2em;
  margin-top: 27px;
  font-size: 37px;
}
.central-meta2 .form-control {
  height: auto;
  font-size: 17px;
  line-height: 27px;
  color: #fd6500;
  background: #edf2f6 none repeat scroll 0 0;
  border: 1px solid #e4e4e4;
}
.central-meta2 .btn-primary {
  background-color: #ff1100;
  border: 1px solid #ff1100;
  color: #fff;
  margin: 0 auto;
  display: block;
  padding: 7px 27px 7px 27px;
  font-size: 20px;
}
.myaccount-content {
  border-bottom: 1px dashed #cecece;
  padding-bottom: 10px;
  line-height: 1.2em;
  font-weight: 900;
  margin-bottom: 47px;
  font-size: 30px;
}
.zzz2 {
  position: relative;
}
.zzz {
  position: absolute;
  right: 10%;
  top: 23%;
  text-align: center;
  display: block;
  margin: 0 auto;
  background-color: #ff1100;
  border: 1px solid #ff1100;
}
.form-group2{
  display: block;
  margin: 37px auto;
}
.acqcss2 .menu-item {
  list-style: none;
  background-color: #ff1100;
  padding: 7px 17px 7px 17px;
  margin-bottom: 27px;
  display: inline-block;
  margin-right: 17px;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.radioqqq {
  display: block;
  float: left;
  background-color: rgb(255, 255, 255);
  padding: 17px 7px 17px 7px;
  margin: 10px 0px 10px 0px;
  position: relative;
  width: 100%;
}
.radioqqq label {
  color: white;
  font-size: 17px;
}
.radioqqq h2 {
  margin: 0 auto 7px;
  display: inline-block;
  text-align: center;
  color: #5a3733;
  font-size: 31px;
  line-height: 37px;
  font-weight: 600;
  vertical-align: text-bottom;
  letter-spacing: 0.7px;
}
.price {
  display: inline-block;
  text-align: center;
  font-family: 'Signika', serif;
}
.radioqqq .radio {
  display: inline;
  opacity: 0;
  margin: 0;
  overflow: hidden;
  -webkit-appearance: none;
}
.radioqqq .radio__label {
  display: inline-block;
  height: 50px;
  position: relative;
  padding: 10px 13px 15px 48px;
  text-align: right;
  cursor: pointer;
  margin: 0 auto;
  font-size: 20px;
  color: black;
  background-color: #f8f7f2;
  box-shadow: 1px 3px 7px #bdbcbc;
}
.radioqqq .radio__label:before, .radioqqq .radio__label:after {
  position: absolute;
  content: "";
  -webkit-transition: border-color 200ms ease, -webkit-transform 200ms ease;
  transition: border-color 200ms ease, -webkit-transform 200ms ease;
  transition: transform 200ms ease, border-color 200ms ease;
  transition: transform 200ms ease, border-color 200ms ease, -webkit-transform 200ms ease;
  box-sizing: border-box;
  border-radius: 100%;
}
.radioqqq .radio__label:hover:before {
  border-color: #fb872b;
}
.radioqqq .radio__label:before {
  left: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border: 2px solid #dbdbdb;
}
.radioqqq .radio__label:after {
  top: 15px;
  left: 15px;
  width: 20px;
  height: 20px;
  transform: scale(0);
  background-color: #ffffff;
}
.radioqqq .radio__label:hover {
  color: white;
  background-color: #4caf50;
}
.radioqqq .radio__label:hover:before {
  border-color: #fb872b;
}
.radioqqq .radio:checked + .radio__label {
  color: rgba(255, 255, 255, 0.87);
  background-color: #4CAF50;
}
.radioqqq .radio:checked + .radio__label:before {
  border-color: #ffffff;
}
.radioqqq .radio:checked + .radio__label:after {
  transform: scale(1);
}
.listing-duration {
  font-size: 20px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  padding: 0 7px 0 7px;
  color: #000000;
  letter-spacing: 0.7px;
  line-height: 37px;
  white-space: break-spaces;
}
.ppp2 p{
    color: #ff1100;
    font-size: 23px;
    border: 1px dashed #ff1100;
    padding: 17px;
    line-height: 47px;
}
.currency {
  display: inline-block;
  margin-top: 0px;
  vertical-align: top;
  font-size: 33px;
  font-weight: 300;
  color: #ffffff;
}
.value {
  font-size: 33px;
  font-weight: 900;
  margin-left: 0px;
  font-family: 'Signika', serif;
  color: #ffffff;
}
.duration {
  font-size: 1.4rem;
  color: #fd6500;
  display: none;
}
.duration::before {
  content: '/';
  margin-right: 2px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.addbook{
  padding: 67px 0 67px 0;
  /* background-image: linear-gradient(rgb(255 255 255 / 76%), rgb(255 255 255 / 76%)), url(./Img/sss3left.png); */
}
.addbook h3{
    color: #5A3733;
    text-align: left;
    line-height: 1.2em;
    font-weight: 900;
    font-family: 'Signika', serif;
    margin-bottom: 67px;
    font-size: 37px;
}
.bookads2{
  position: relative;
  margin: 0 auto;
  padding: 67px 0 67px 0;
  float: left;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 87px;
}

.text_shadowbook2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #eeeef0;
  z-index: 0;
  left: 0;
  width: 161px;
  background-color: #ffffff;
  height: 100%;
}
.text_shadowbook2s {
  position: absolute;
  top: 28%;
  transform: translateY(-24%);
  color: #eeeef0;
  z-index: 0;
  left: 60px;
  width: 161px;
  background-color: #f6f6f6;
  height: 88%;
}
.public-DraftEditor-content {
  min-height: 300px;
  max-height: 300px;
  padding: 10px 27px 10px 27px;
  white-space: nowrap!important;
}
.rdw-editor-main {
  background-color: #edf2f6;
  border: 1px solid #edf2f6;
  margin-bottom: 17px;
}
.bookads2 .adverts-form-required {
  font-weight: 700;
  color: #e9322d;
  font-size: 27px;
}
label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
  letter-spacing: 1px;
  margin-top: 10px;
}
.bookads2 .previewimg {
  max-width: 167px;
  margin-bottom: 17px;
}
.removeqqq {
  margin: 0 0 0 27px;
  background: linear-gradient(180deg,#fb6a1f,#fb6767 0,#ff2020 99%);
  color: #fff;
  outline: none;
  padding: 7px 17px;
  font-size: 16px;
  border-radius: 7px;
}
.btn-primary {
  color: #fff;
  background: #0ca9f3;
  float: left;
  margin-top: 27px;
  font-size: 26px;
  padding: 10px 27px 10px 27px;
  box-shadow: 0px 7px 10px #d7dadf;
  border-radius: 7px;
  border: 0;
}
.Toastify__toast--error{
  background: #ff4400!important;
}
.btn-primary .svg-inline--fa {
  height: 23px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.bookadspreview .text_shadowbook2s {
  position: absolute;
  top: 28%;
  transform: translateY(-23%);
  color: #eeeef0;
  z-index: 0;
  left: 60px;
  width: 161px;
  height: 87%;
}
.zzz7 {
  margin-left: 27px;
  background-color: white;
  padding: 10px;
  border-radius: 7px;
}
.zzz7 h3 {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: #444040;
}
.zzz7 .svg-inline--fa {
  width: 17px;
  color: #0ca9f3;
  margin-right: 3px;
}
.zzz7 h3 p {
  color: #008eff;
}

.zzz7 p {
    position: relative;
    font-size: 17px;
    display: inline-block;
    cursor: pointer;
    word-break: break-all;
}
.zzz8s {
  box-shadow: 0px 1px 11px #928f8f;
  float: left;
  width: 97px;
  height: 97px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  border-radius: 100%;
  margin: 0 auto!important;
  display: block;
}
.usernamesss {
  margin-left: 17px;
  float: left;
  margin-top: 27px;
  font-weight: 600;
  color: #000000;
  font-size: 22px;
}
.redmoesss {
  height: 300px;
  overflow: hidden;
  margin-top: 17px;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0.7px;
}
.redmoesssqqq{
  height: auto!important;
}
.redmoesss span{
  font-family: 'Lato', sans-serif!important;
  font-size: 17px!important;
  line-height: 37px;
  letter-spacing: 0.7px;
}
.select-icon{
  vertical-align: top;
  padding: 0;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: left;
  margin-right: 17px;
}
.select-txt{
  display: inline-block;
}
.bookadspreview input[id^=radio] {
  display: none;
}
.bookadspreview input[id^=radio]:checked+label {
  height: 26px;
  width: 26px;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #795548;
  background-color: #ff1100;
  margin-right: 13px;
  margin-top: 0;
}
.bookadspreview input[id^=radio]+label {
  height: 26px;
  width: 26px;
  display: inline-block;
  padding: 0;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  float: left;
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #fff;
  margin-bottom: 2px;
  background-color: #fff;
  border: 1px solid #656565;
  margin-right: 13px;
  margin-top: 0;
}
.bookadspreviewlsss {
  background-color: white;
  padding: 37px 37px 0px 37px;
  border-radius: 13px;
  box-shadow: 0 0 5px #e1e1e1;
  margin-bottom: 60px;
}
.bookadspreview{
  position: relative;
  margin: 0 auto;
  padding: 67px 0 67px 0;
  float: left;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
}
.bookadspreview .text_shadowbook2 {
  background-color: #f6f6f6;
}
.bookadspreview .text_shadowbook2s {
  background-color: #ffffff;
}
.redmoesssmmm{
  color: #ff1100;
    font-size: 17px;
    padding-bottom: 17px;
    cursor: pointer;
}
.bookadspreview h3 {
  margin-bottom: 17px;
  font-size: 18px;
  margin-top: 17px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  color: #000000;
  clear: both;
}
.bookadspreview .zzz7 h3 span {
  color: #605f5e;
  line-height: 30px;
}
.bookadspreviewlsss h1 {
  color: #ff1100;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 27px;
}
.bookadspreviewlsss .stripsss{
  color: #6873e5;
  font-size: 26px;
  font-weight: 700;
  font-family: 'Signika', serif;
}
.bookadspreview .lll h1 {
  font-size: 27px;
}
.bookadspreviewlsss .thm-btn {
  background-image: -webkit-linear-gradient(45deg, #1477c5 15%, #51a7ec 65%);
  padding: 13px 22px 13px 27px;
  font-size: 23px;
  margin: 10px auto 37px;
  display: table;
  font-weight: 700;
  cursor: pointer;
  float: left;
}
.bookadspreviewlsss .thm-btn:hover {
  background-image: -webkit-linear-gradient(45deg, #f87070 15%, #fb6a1f 65%);
  color: white;
}
.masssqqq {
  margin-top: 47px;
}
.yyy{
  border-right: 1px dashed #d8d6d6;
  padding-right: 57px;
}
.yyy h1 {
  font-size: 24px;
  margin-top: 0;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.7px;
  line-height: 37px;
  text-transform: capitalize;
  font-weight: 600;
}
.zzz8 {
  background-color: #ffffff;
  margin-top: 27px;
  border-radius: 17px;
  position: relative;
}
.leqsss3:before {
  top: 0px!important;
}
.zzz8:before {
  content: '';
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border: 1px dashed hsl(87, 58%, 45%);
  position: absolute;
  top: 12px;
  left: 12px;
}
.sqqssqqq{
  float: right;
}
.Pay{
  font-size: 26px;
  font-weight: 700;
  font-family: 'Signika', serif;
  color: #173c91;
}
.pal{
  font-size: 26px;
  font-weight: 700;
  font-family: 'Signika', serif;
  color: #16a1e0;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.pymt-type-name {
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 0px;
  color: #3c444c;
  margin-bottom: 7px!important;
}
.pymt-type-desc {
  font-size: 16px;
  color: #333232;
  margin: 10px 0 10px 0;
}
.payment-row {
  background-color: #ffffff;
  width: 217px;
  margin: 0px 0px 0px 30px;
  padding: 22px 30px 10px 30px;
  text-align: left;
  display: inline-block;
  position: relative;
  box-shadow: 2px 3px 7px #d2d2d2;
}
.aaa5 {
  width: 100%;
  clear: both;
  float: left;
  position: relative;
  margin-top: 27px;
  border: 1px solid #f3a39f;
  padding: 37px;
  border-radius: 7px;
}
.aaa5 h4 {
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 37px;
  font-size: 27px;
  line-height: 47px;
  color: #0679e8;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 2px 1px 1px #cdcdce;
}
.payment-titleqqq{
  color: #ff7207;
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 23px;
  line-height: 47px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.bookads2 .central-meta {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  margin-bottom: 60px;
  padding: 67px;
  margin-top: 37px;
  position: relative;
  box-shadow: 3px 3px 10px #e0e0e0;
}
.bookads2 .lll .book-wrapper2 img {
  max-width: 167px;
  height: 227px;
  box-shadow: -1px 5px 13px #666464;
  border: 4px solid white;
}
.bookads2 .lll h1 {
  font-size: 30px;
}
.bookads2 .aaa4 h4 {
  font-size: 20px;
  margin: 10px auto 27px;
  height: 32px;
}
.bookads2 .aaa4 {
  padding: 27px 7px 37px 7px;
}
.bookadspreview .bookads2 {
  padding: 0;
  background-image: none;
  background-color: white;
}
.bookads2 .adverts-form-required2 {
  font-weight: 400;
  color: #e9322d;
  font-size: 17px;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  float: left;
  display: block;
  width: 100%;
}
.yyy2 {
  color: #000000;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 34px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.btn-primary2 {
  background-image: -webkit-linear-gradient(45deg, #747272 15%, #b8b0b0 65%);
  padding: 10px 22px 10px 27px;
  font-size: 23px;
  margin: 30px auto 0;
  font-weight: 700;
  cursor: pointer;
  color: white;
  border: 0;
  border-radius: 10px;
}
.xxx{
  color: #5A3733;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 67px;
  font-size: 37px;
}
.bookadspreview .addbook {
  padding: 0;
  background-image: none;
}
.bookadspreview iframe{
    width: 600px;
    height: 400px;
    margin: 67px auto;
    display: block;
    box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
    color: #000;
    border-radius: 10px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.disablsss, .disablsss input {
  cursor: not-allowed;
}
.disablsss {
  -webkit-filter: blur(1px);
  opacity: .2;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.bookvissss .zzz8:before {
    z-index: -7;
}
.successs{
  padding: 60px 0px 60px 0px;
  position: relative;
  float: left;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
}
.successs .text_shadowbook2 {
  background-color: #f6f6f6;
}
.successs .text_shadowbook2s {
  background-color: #ffffff;
}
.successs .text_shadowbook2s {
  position: absolute;
  top: 27%;
  transform: translateY(-24%);
  color: #eeeef0;
  z-index: 0;
  left: 60px;
  width: 161px;
  height: 90%;
}
.successs .breadcrumbs h1 {
  text-align: left;
  margin-bottom: 47px;
}
.ooo2 {
  background-color: white;
  padding: 67px;
  box-shadow: -10px 17px 10px #c5c5c542;
  height: fit-content;
}
.ooo2 h2{
    position: relative;
    color: #4CAF50;
    line-height: 1.2em;
    font-weight: 900;
    font-family: 'Signika', serif;
    margin-bottom: 27px;
    font-size: 27px;
}
.ooo2 h3{
  margin-bottom: 17px;
  font-size: 20px;
  margin-top: 27px;
  font-family: "Lato",sans-serif;
  font-weight: 700;
  line-height: 37px;
}
.ooo2 .thm-btn {
  background-color: #ff1100;
  background-image: -webkit-linear-gradient(45deg, #f32727 15%, #f75f5f 65%);
  padding: 10px 27px 13px 27px;
  font-size: 20px;
  letter-spacing: 0.7px;
  color: white;
  border-radius: 7px;
  margin-top: 17px;
  float: left;
}
.iii2{
    position: absolute;
    top: -67px;
    left: -57px;
    padding: 10px 22px 13px 27px;
}
.iii3 {
  position: absolute;
  bottom: -67px;
  left: -33px;
}
.error{
  color: #ff1100;
}
.iii4 {
  margin-bottom: 27px;
  position: relative;
  display: inline;
  background: #795548;
  border-radius: 5px;
  font-weight: 600;
  text-align: left;
  padding: 17px 27px 17px 27px;
  font-size: 20px;
  float: left;
  background-color: #ff1100;
  background-image: -webkit-linear-gradient(
45deg,#5ac3f5 15%,#0ca9f3 65%);
  color: white;
  white-space: nowrap;
}
.iii4 a{
  color: white;
}
.iii5 {
  margin-bottom: 27px;
  position: relative;
  display: inline;
  background: rgb(255 71 71);
  border-radius: 5px;
  font-weight: 600;
  text-align: left;
  padding: 13px 27px 13px 27px;
  font-size: 20px;
  float: left;
  margin-right: 27px;
  background-color: #ff1100;
  color: white;
  background-image: -webkit-linear-gradient(45deg,#f32727 15%,#f75f5f 65%);
}
.iii5:hover {
  color: rgb(0, 0, 0);
}
.iii5 a{
  color: white;
}
.profileimgsssbooks {
  width: 167px;
  max-width: 167px;
  max-height: 167px;
  min-width: 167px;
  min-height: 167px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  height: 167px;
  border-radius: 10%;
  box-shadow: 1px 7px 7px 0px #cac6c6;
  margin: 0 auto;
  display: block;
}
.uuu h2{
  color: #ff1100;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: "Signika",serif;
  margin-bottom: 17px;
  font-size: 30px;
  margin-top: 27px;
}
.zzz8s {
  background-color: #ffffffc9;
  margin-top: 17px;
}
.bookadspreview .zzz8s h3 {
  font-size: 18px!important;
  font-weight: 400;
}
.zzz8s .zzz7 .svg-inline--fa {
  width: 17px;
  color: #ff1100;
  margin-right: 7px;
}
.zzz8s .zzz7{
  cursor: pointer;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.pagination {
  margin-left: 77px;
}
.pagebooks{
  padding: 67px 0 67px 0;
  float: left;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}
.pagebooks .lll .book-wrapper2 img {
  max-width: 167px;
  height: 227px;
  box-shadow: -1px 5px 13px #666464;
  border: 4px solid white;
}
.pagebooks .text_shadowbook2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #eeeef0;
  z-index: 0;
  left: 0;
  width: 161px;
  height: 100%;
}
.pagebooks .text_shadowbook2s {
  position: absolute;
  top: 27%;
  transform: translateY(-24%);
  color: #eeeef0;
  z-index: 0;
  left: 60px;
  width: 161px;
  height: 90%;
}
.inner-box .app {
  margin-bottom: -77px;
}
.pagebooks2 h2 {
  position: relative;
  color: #000000;
  line-height: 1.2em;
  font-weight: 900;
  font-family: "Signika",serif;
  margin-bottom: 7px;
  font-size: 37px;
  text-align: left;
}
.lower-content h3 {
  position: relative;
  color: #000000;
  line-height: 27px;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  text-align: center;
  height: 57px;
  overflow: hidden;
}
.lower-content h6{
  font-family: 'Lato', sans-serif;
  color: #ff1100;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.news-block-one {
  width: 100%;
  margin-bottom: 17px;
  margin-top: 17px;
  float: left;
}
.base-btn1 {
  background-image: linear-gradient(to bottom right,#2cbeff 0,#0887c3)!important;
}
.members .base-btn1 {
  border-left: 3px solid black;
}
.wqmmm .text_shadowbook2s {
  top: 26%;
  height: 90%;
}
.wqmmm2 .text_shadowbook2s {
  top: 26%;
  height: 90%;
}
.coverDesign2tabqqq img {
  box-shadow: -1px 5px 13px #f7fafe;
  border: 3px solid #edf4fa;
  height: 177px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  margin-bottom: 17px;
  object-fit: contain;
  object-position: center center;
  background-color: #edf4fa;
}
.coverDesign2tabqqq2 img {
  box-shadow: -1px 5px 13px #f7f9fd;
  border: 3px solid white;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  margin-bottom: 47px;
  height: 313px;
  max-width: 217px;
}
.bookads2 .text_shadowbook2 {
  width: 147px;
}
.bookads2 .text_shadowbook2s {
  top: 27%;
  height: 92%;
}
.news-block-one .base-btn1 {
  margin-top: 0px;
  font-size: 17px;
  font-weight: 600;
  padding: 0px 27px;
  display: table;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  line-height: 47px;
}
.date-publishedqqq{
  float: right;
  color: #898989;
  font-size: 17px;
  margin-bottom: 10px;
}
.imsss{
  width:100%;
}
.page-item.active .page-link{
  background-color: #26b4f5;
  border-color: #159bd9;
}
.page-link {
  color: #26b4f5;
}
.yyy iframe {
  width: 517px;
  height: 317px;
  margin: 17px auto;
  display: block;
  box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
  color: #dbdbdb;
  border-radius: 10px;
}
.basccsss2 {
  padding: 0;
  height: 577px;
  overflow: hidden;
  border-bottom: 1px solid #e6e6fa;
  margin-bottom: 47px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.standsss{
  background: #f7f7f7;
  border-width: 0 1px 1px 0;
  border-style: solid;
  transition: all .4s ease;
  border-color: rgb(158,158,158,.2);
  border-top: 1px solid #e5e5e5;
}
.standsss2 {
  padding: 47px 17px 47px 17px;
}
.standsss2 h6{
  font-size: 17px;
}
.articles:hover{
  background-color: white;
}
.post-thumb {
  position: relative;
  max-width: 100%;
  transition: all .5s ease;
  padding-left: 50px;
  padding-right: 20px;
}
.post-thumb:before {
  content: ' ';
  position: absolute;
  width: 1px;
  height: 50px;
  left: 8px;
  top: -40px;
  background: rgba(0,0,0,.15);
}
.articles .date-published {
  opacity: .5;
}
.date-published {
  transform: translateY(0) rotate(270deg);
  position: absolute;
  line-height: 1px;
  transform-origin: center right;
  top: 0;
  right: calc(100% - .5em);
  margin-top: 10px;
  text-transform: uppercase;
  white-space: nowrap;
}
.date-published span.big {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  display: block;
  text-transform: uppercase;
}
.articles .post-thumb a {
  border-radius: 0 40px 0 20px;
}
.post-thumb a:not(.category-link) {
  background: 0 0;
}
.post-thumb a {
  display: block;
  border-radius: 8px;
}
.post-thumb img{
  border-radius: 0 40px 0 20px;
}
.post-title {
  max-width: 60%;
  font-size: 24px;
  line-height: 1.28em;
  margin-top: 0;
  margin-bottom: 10px;
  letter-spacing: -.5px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  position: relative;
  margin-top: 10px;
  color: #2b2d42;
  height: 120px;
  overflow: hidden;
}
.post-title a {
  display: inline;
  padding-bottom: 5px;
  background-repeat: no-repeat;
  background-position: left 80%;
  transition: all .3s ease;
  color: #5a3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: "Signika",serif;
  margin-bottom: 17px;
  font-size: 23px;
}
.articles:hover .post-title a {
  background-size: 100% 1px;
  color: #f64442;
  background-image: linear-gradient(90deg, #f64442 0%, #e00905 100%);
}
.articles:hover::after {
  opacity: 1;
  width: calc(100% + 1px);
}

.articles:after {
  position: absolute;
  content: ' ';
  width: 0;
  height: 5px;
  left: 0;
  top: -1px;
  opacity: 0;
  transition: all .4s ease;
  background-image: linear-gradient(90deg, #f64442 0%, #ff100c 100%);
}
.articles {
  border-width: 0 1px 1px 0;
  border-style: solid;
  transition: all .4s ease;
  background: #f7f7f7;
  border-color: rgb(158,158,158,.2);
  height: 537px;
  overflow: hidden;
}
.articles:hover .arrow-read-more {
  opacity: 0;
  transform: translateX(100%);
}
.arrow-read-more {
  position: absolute;
  right: 10px;
  bottom: 22px;
  transform: translateX(0);
}
.arrow-read-more, .arrow-read-more-2 {
  overflow: hidden;
  transition: all .2s ease;
  
}
.arrow-read-more svg, .arrow-read-more-2 svg {
  width: 22px;
  height: 22px;
}
.arrow-read-more svg {
  fill: rgba(16,17,24,.2);
  color: rgba(16,17,24,.2);
}
.articles:hover .arrow-read-more-2 {
  transform: translateX(0);
  opacity: 1;
  color: #2b2d42;
}
.articles .arrow-read-more-2:hover {
  color: #f64442;
}
.arrow-read-more-2 {
  position: absolute;
  right: 10px;
  bottom: 26px;
  transform: translateX(-100%);
  opacity: 0;
  font-weight: 700;
  transition: all .3s ease;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.ttt img {
    max-width: 197px;
    width: auto;
    height: 267px;
    overflow: hidden;
    object-fit: cover;
    object-position: center top;
    margin: 0 auto;
    display: block;
}
.ttt2 {
  position: relative;
  opacity: 1;
  padding: 47px 10px 27px 10px;
  margin: 0 auto;
  background-color: #f7fafe;
  box-shadow: 3px 2px 7px #e2dede;
  -webkit-clip-path: polygon(0 30px, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 30px, 100% 0, 100% 100%, 0% 100%);
  border: 2px solid #e1f4fe;
}
.ttt:hover .ttt2{
  opacity: 1;
}
.lll .book-wrapper2 img {
  border: 4px solid white;
}
.bg-lines {
  position: absolute;
  height: 100%;
  left: 20px;
  right: 20px;
  top: 7%;
}
.bg-lines div:nth-child(1) {
  display: none;
}
.bg-lines div {
  background: rgba(0,0,0,.06);
  width: 1px;
  height: 100%;
  position: absolute;
}
.bg-lines div:nth-child(2) {
  margin-left: 23%;
}
.bg-lines div:nth-child(3) {
  margin-left: 49%;
}
.bg-lines div:nth-child(4) {
  margin-left: 73%;
}
.bg-lines div:nth-child(5) {
  margin-left: 100%;
}
.ttt .date-published {
  right: calc(100% - -2.5em);
}
.ttt .post-thumb:before {
  left: -40px;
  transition: all .4s ease;
}
.ttt:hover .post-thumb:before {
  background: rgb(255 71 71);
}
.ttt3 img {
  max-width: 227px;
  height: 227px;
}

.ttt4 h2 {
  font-size: 23px;
}
.ttt6 .booksrec2 img {
  margin: 0px auto;
  max-width: 240px;
  max-height: 137px;
}
.ttt6 .booksrec2 {
  height: 137px;
  overflow: hidden;
}
.ttt6 h2 {
  font-family: "Lato",sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  margin-bottom: 17px;
  margin-top: 10px;
  text-align: center;
}
.ttt6 .aaa3 {
  padding: 17px;
}
.ttt5 .videosss2 {
  width: 387px;
  height: 247px;
  margin: 27px auto 27px;
}
.ttt6 .base-btn1 {
  margin-top: 0px;
  font-size: 17px;
  font-weight: 400;
  padding: 10px 17px;
  display: table;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  line-height: normal;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.search .book-wrapper img {
  max-width: 177px;
  height: 177px;
}
.search .stansss h4 {
  margin: 7px 0 17px 10px;
  font-size: 18px;
  max-height: 97px;
  line-height: 33px;
  overflow: hidden;
  color: #000;
  font-weight: 400;
}
.search .stansss {
  height: 267px;
}
.search .stansss p {
  margin: 30px 0 0px 0;
  font-size: 20px;
}
.search .stansss h6 {
  font-size: 20px;
}
.search .description {
  float: left;
  margin-top: 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #e6e6fa;
  margin-bottom: 27px;
  padding-bottom: 17px;
  height: 247px;
  overflow: hidden;
}
.search .active {
  color: #fd6500!important;
}
.searchbar {
  margin: 60px auto 37px;
  position: relative;
  display: table;
}
.search .searchbar input {
  font-size: 23px;
  width: 700px;
  height: 5rem;
  border: none;
  outline: none;
  border-radius: 0rem;
  box-shadow: 0 1rem 2rem rgba(0,0,0,.1);
  padding: 2rem;
  font-family: Roboto,sans-serif;
  border: 2px solid #795548;
}
.search .searchbar .btnmmm {
  height: 2.7rem;
  position: absolute;
  top: 7px;
  right: 17px;
  transition: all .2s;
  transition-delay: .1s;
  border: 0;
  padding: 17px;
  background-color: #f67d4100;
  color: #f67d41;
  font-size: 23px;
  outline: none;
}
.search .searchbar .btnmmm img {
  height: 20px;
  top: 1.5rem;
  right: 1rem;
  transition: all .2s;
  transition-delay: .1s;
}
.search .searchbar .btnmmm:hover {
  color: #34465e;
}
.search {
  float: left;
  width: 100%;
  background: #ffffff;
  min-height: 100vh;
}
.search .nav-tabssss li {
  color: #000;
  white-space: nowrap;
  border: 0px solid #e6e6fa;
  padding: 17px;
  width: 76%;
  background-color: #fbfbfb;
  display: block;
  margin-bottom: 17px;
  border-radius: 7px;
  font-weight: 600;
  font-size: 17px;
  box-shadow: 1px 1px 4px gainsboro;
}
.set-title h5 {
  border-bottom: 1px dashed #cecece;
  padding-bottom: 10px;
  margin-bottom: 27px;
  color: #ff1100;
  font-size: 27px;
  font-weight: 600;
}

.search .stansss h6 {
  font-size: 20px;
  margin: 0 auto 7px 10px;
  text-align: left;
  color: #ff1100;
  font-weight: 300;
  line-height: 27px;
}
.Toastify__toast--default {
  background: #ffffff;
  color: rgb(0, 0, 0);
}
.search .booksrec4 {
  position: relative;
  overflow: hidden;
  float: left;
  width: 100%;
}
.search .booksrec4 img {
  max-height: 227px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  box-shadow: -3px 1px 6px #5f5d5d;
  border-radius: 10px;
}
.search .videosss2 {
  width: 92%;
  height: 207px;
  margin: 27px auto 17px;
  display: block;
  position: relative;
  box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
  color: #000;
  border-radius: 10px;
}
.search .booksrec2 img {
  width: 117px;
  height: 117px;
  object-fit: contain;
  object-position: center center;
  margin: 0 0 7px;
  display: block;
  box-shadow: 0 0 3px #777570;
}
.search .single-btn2 {
  margin-left: 17px;
  max-height: 147px;
  overflow: hidden;
  margin-top: 3px;
}
.search .description h6 {
  color: #000;
  font-size: 20px;
  margin-bottom: 17px;
  height: 50px;
  overflow: hidden;
}
.audiosssbbb h4, .cccgggsss2 {
  overflow: hidden;
  text-align: center;
  color: #000;
}
.audiosssbbb h4 {
  margin: 0 auto 13px;
  font-size: 17px;
  max-height: 60px;
  width: 87%;
  float: none;
  line-height: 27px;
}
.audiosssbbb h6 {
  color: #397101;
  text-align: center;
  margin-bottom: 10px;
  font-family: Roboto,sans-serif;
  font-size: 17px;
}
.cccgggsss2 {
  height: 40px;
  margin: 0 auto;
  display: block;
  width: 100%;
  padding-top: 13px;
}
.Appfooter h1 {
  font-size: 30px;
  font-weight: 600;
  color: #ff1100;
  margin-bottom: 17px;
}
.ttt7 .menu-item {
  float: left;
  margin-right: 17px;
  margin-bottom: 7px;
}
.ttt7 .menu-item a {
  color: #000000;
  font-size: 17px;
  text-transform: capitalize;
}
.ttt8{
  position: relative;
  width: 100%;
  float: left;
  z-index: 7;
  margin-top: 7px;
  margin-bottom: 27px;
}
.ttt8 .ttt8s:hover img{
  margin: 0px 10px 20px 0px;
}
.ttt8 .ttt8s:hover span{
  opacity: 1;
}
.ttt8s {
  display: block;
  position: relative;
}

.ttt8s span{
  opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: -63px;
    font-size: 17px;
    background-color: white;
    color: #f64442;
    padding: 17px;
    transition: .30s;
}
.ttt8s img {
  width: 66px;
  height: 66px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  border-radius: 100%;
  margin: 0px 10px 20px 0px;
  z-index: 10;
  transition: .30s;
}
.menu-itemcopyright .svg-inline--fa {
  color: #f64442;
}
.topslider5:before{
  content: "";
  position: absolute;
  height: 100%;
  top: -47px;
  left: 0;
  width: 100%;
  background: url('./Img/bg-top-3.webp') ;
  background-size: contain;
}
.desc23sss{
  height: 130px;
  overflow: hidden;
  font-size: 16px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-bottom: 0;
  font-style: normal;
  background-color: rgb(255 255 255 / 0%)!important;
}
.desc23sss p{
  background-color: rgb(255 255 255 / 0%)!important;
  text-align: center;
}
.desc23sss span{
  background-color: rgb(255 255 255 / 0%)!important;
  text-align: center;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.central-metasearch {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #795548;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  padding: 37px 17px 27px;
  position: relative;
}
.eee {
  padding: 0;
  margin-top: 37px;
}
.eee2 {
  padding: 0;
  margin-top: 37px;
}
.eee3 {
  padding: 0;
  margin-top: 37px;
}
.eee img{
  max-width: 160px;
  margin: 27px 0 17px 0;
}
.eee2 img{
  max-width: 260px;
  margin: 27px 0 17px 0;
}
.eee3 img{
  max-width: 160px;
  margin: 27px 0 17px 0;
}
.eee h2, .eee2 h2, .eee3 h2{
  position: relative;
  color: #ff1100;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 37px;
}
.copyright-block a {
  font-size: 18px;
}
.eee .videosss2 {
  width: 460px;
  height: 300px;
}
.lll .book-wrapper2 img {
  box-shadow: -1px 1px 10px #807f7f;
  margin: 10px auto 17px;
  display: block;
  max-width: 137px;
  height: 197px;
  transition: .3s;
  border-radius: 10px;
}
.lll h1 {
  color: #5a3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 600;
  margin-bottom: 17px;
  font-size: 27px;
}
.ttt7{
  margin: 17px auto 0px;
  text-align: center;
  display: table;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.publsssqqq{
  position: relative;
  padding: 0px 0 107px 0;
  width: 100%;
  float: left;
}
.publsssqqq h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
}
.publsssqqq .aaa3 {
  box-shadow: 0 20px 40px 0 rgba(28,30,35,0.2);
  border: 0px solid #f0f2f3;
  padding: 30px 30px;
}
.sfdfgsss img {
  max-width: 197px;
  max-height: 137px;
}
.sfdfgsss h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin: 27px auto 17px;
  display: block;
  height: 57px;
  overflow: hidden;
  font-family: "Lato",sans-serif;
  color: #ff1100;
}
.sfdfgsss .desc23sss {
  height: 127px;
}
.news-block-one .subcolor-main{
  display: none;
}

ul.main-menu{white-space: nowrap;}
.ttt7{white-space: nowrap;}
.copyright-block p{white-space: pre-wrap;}
.base-btn1{white-space: nowrap;}
.btn{white-space: nowrap;}
.thm-btn{white-space: nowrap;}
label{white-space: nowrap;}
.search .nav-tabssss li {
  cursor: pointer;
}
.leqsss {
  height: 377px;
  margin: 27px auto 17px;
  display: block;
  border-radius: 4%;
  box-shadow: hsl(0deg 0% 80%) 0 0 16px;
  max-width: 247px;
  object-fit: cover;
}
.leqsssaudio {
  max-height: 377px;
  margin: 27px auto 17px;
  display: block;
  border-radius: 4%;
  box-shadow: hsl(0deg 0% 80%) 0 0 16px;
  max-width: 247px;
  object-fit: cover;
}




@media(max-width:480px){
  .ttt5 .videosss2 {width: 260px;height: 207px;}
  .ttt3 img {max-width: 203px;height: 227px;}
  .memberstabsss li {padding: 10px;font-size: 13px;margin: 0 7px 10px 7px;}
  .zzz8s .zzz7 {overflow: hidden;margin-top: 17px;margin-left: 13px;margin-right: 17px;}
  .zzz7 p {word-break: break-all;}



}





@media(max-width:767px){
.topslider62 h1 {text-align: center;font-size: 32px;}
.bosss .Recommended__cols {justify-content: flex-start;align-items: stretch;flex-direction: column;}
.sssqqsmmnnn{padding: 0!important;}
.aaalasssqqq2 {display: none!important;}
.topslider63.Latest__Homes .rwt__tab{font-size: 14px!important;padding: 7px 4px 7px 4px!important;margin: 0px 0px 17px 0px!important;}
.topslider6.Recommended__homes h1 {padding: 0px 0 0px 0!important;}
.Featured__home .Featured__home__sec .bookscovers img {height: 127px;border-radius: 7px;max-width: 77px;}
.Highlighted__homes__sec .Highlighted__homes__content {max-height: 177px;height: auto;margin-bottom: 0;}
.topslider65.Featured__home .Featured__home__sec {padding: 17px;}
.usernamesss {margin-top: 17px;font-size: 20px;}
.usernamesss2 {margin-top: 17px;font-size: 20px;}
.profilepageleftsss{margin-top: 37px;}
.img-fluid {height: 300px;margin: 0 auto 27px;display: block;}
.Recommended__homes__Lefts h2 {text-align: center;}
.bann{text-align: center;}
.desc2 p, .desc2 span {text-align: center!important;}
.topslider6.Recommended__homes .Recommended__homes__centers {box-shadow: none;margin: 37px 0px 10px;padding: unset;}
.topslider6.Recommended__homes .Recommended__homes__cover img {height: 215px;margin: 10px auto 17px;}
.Recommended__homes__centers h6.Price {text-align: center;}
.topslider6.Recommended__homes .bosss h2 {text-align: center!important;font-size: 24px;line-height: 34px;}
.topslider6.Recommended__homes .Recommended__homes__content p, .Recommended__homes__content span {text-align: center!important;}
.bosss p {font-size: 18px;color: black; margin-bottom: 7px;line-height: 37px; text-align: center;}
.topslider65.Featured__home {padding: 47px 0px 24px 0px;margin-top: 0px;}
.topslider65 h1 {font-size: 33px;margin-bottom: 23px;}
.topslider64 h6 {font-size: 17px;padding: 0px 17px 0px 0px;}
.res5c {background-color: #0ca9f3!important;cursor: pointer;}
.menuq2 {width: 100%;margin: 0px auto;padding: 17px;border-top: 0px solid #ff1100; box-shadow: 1px 1px 7px #cccccc;position: absolute;left: 0;background-color: white;}
.menuq2 .sub-menu {width: 90%; margin: 17px auto 0;padding: 17px;border-left: 4px solid #0ca9f3;box-shadow: 1px 1px 7px #cccccc;float: left;}
.top_header_area .sub-menu li {float: left;}
.text_shadow {display: none;}
.topslider6 h1 {font-size: 30px; margin-bottom: 10px;}
.Featured__home h2 {font-size: 16px;margin:0px 0px 10px 0px;height: 53px;line-height: 27px;}
.topslider64 h1 {font-size: 34px; margin-bottom: 17px;}
.topslider64.Video__home {padding: 47px 0px 67px 0px;}
.coverDesign img {height: 237px;margin: 37px auto 0px;}
.text_shadow2 {display: none;}  
.text_shadow2s {display: none;}  
.text_shadowbook2 {display: none;}  
.text_shadowbook2s {display: none;}  
.pagebooks .text_shadowbook2 {display: none;}  
.pagebooks .text_shadowbook2s {display: none;}  
.topslider62 h1 {z-index: 7;}
.topslider66 h1 {z-index: 7;}
.coverDesign2 img { height: 237px;margin: 27px auto 27px;}
.base-btn2 {right: 7%;bottom: 0%;}
.text_shadow3 {display: none;} 
.text_shadow5 {display: none;} 
.topslider63 h1 {line-height: 47px;font-size: 27px; margin-bottom: 37px;}
.topslider63 {padding: 43px 0px 60px 0px;margin: 27px 0 0 0;}
.topslider64 h1 {margin-top: 0;}
.topslider64 {padding: 67px 0px 67px 0px;}
.videosss2 {width: 100%!important;height: 217px!important;margin: 37px auto 0px;}
.topslider62videos {padding-right: 0;}
.card {padding: 10px;}
.base-btn4 {bottom: -5%;}
.topslider65 {padding: 47px 0px 77px 0px;margin-top: 70px;}
.leqsss {height: 237px;margin: 47px auto 37px;}
.zzz8s {width: 107px;height: 107px;}
.col-md-6.ttt5 {padding: 0;}
.col-md-4.ttt6 {padding: 0;}
.leqssswww img {height: auto;margin: 0px auto 17px;}
.zzz7 {margin-left: 0;}
.zzz8 {margin-top: 0;}
.bookadspreview {padding: 37px 0 37px 0;}
.logsss3 {padding: 37px 15px 37px 15px;margin-top: 0;margin-bottom: 60px;}
.login-1 {padding: 37px 0 37px 0;}
.yyy2 {margin-bottom: 0px;font-size: 27px;}
.xxx {line-height: 47px;margin-bottom: 37px;font-size: 27px;}
.bookadspreview .addbook {margin-bottom: 60px;}
.yyy iframe {width: 300px;height: 237px;margin: 37px auto;}
.rwt__tab {font-size: 16px!important;padding: 17px 7px 17px 7px!important;}
.taaa {width: 100%;float: left;background: linear-gradient(180deg,#ffffff 0,#f3f3f3 0,#ffffff 99%);}
.rwt__tabs[data-rwt-vertical="true"] { display: block!important;}
.topslider66 h1 {text-align: center;font-size: 34px;margin-bottom: 27px;}
.forms__nesss .fotter__btnsss {padding: 14px 10px 14px 10px;}
.forms__nesss input.form-controlsss { width: 217px;}
.forms__nesss {margin: 0 auto 37px!important;}
.copyright-block div {flex-direction: column;}
.copyright-block img {margin-right: 0px;}
ul.main-menu>li.menu-item>a {padding-top: 17px; padding-bottom: 0px;font-size: 17px;float:left;}
.top_header_area li {display: block;}
  .addbook .direction-r {white-space: break-spaces;}
  .ttt6 h2{font-size: 20px!important;}
  .members {padding: 37px 0 37px 0;}
  .myaccount-content {font-size: 25px;}
  .profileimgsss {width: 127px;min-width: 127px;min-height: 127px;height: 127px;}
  .header-middle img {margin: 0 auto;display: block;}
  .res5c {padding: 6px 10px 6px 10px;margin: 7px auto;}
  .aaa {display: none;}
  .topslider {padding: 33px 0 32px 0;}
  .topslider4 {padding: 33px 0 47px 0;position: relative;}
  .topslider4 h1 {margin-bottom: 7px;font-size: 33px;}
  .book2 { transform: scale(1.0);}
  .topslider6 {padding: 33px 10px 33px 10px;}
  .topslider5:before {opacity: 0;}
  .ttt7 {padding: 0 17px 0 17px;}
  .ttt8 {display: none;}
  .menu-itemcopyright {right: 0;left: 0;top: -30px;}
  .wpb_single_image.vc_align_right {display: none;}
  .menu-item {display: block;clear: both;margin-bottom: 17px;}
  .sub-menu .menu-item a {color: #000000;}
  .addbook h3 {line-height: 37px;margin-bottom: 17px;font-size: 22px;}
  .bookads2 .central-meta {padding: 17px;}
  .bg-lines {display: none;}
  .addbook .flag {font-size: 23px;}
  .currency { font-size: 27px;}
  .value {font-size: 27px;}
  .pagebooks2 h2 {margin-bottom: 0px;font-size: 30px;}
  .pagebooks {padding: 37px 0 37px 0;}
  .ttt7 .menu-item { clear: unset; margin-bottom: 7px;}
  .bookads2 {padding: 37px 0 37px 0;}
  .yyy {padding-right: 0;}
  .thm-btn {padding: 7px 10px 7px 10px;font-size: 16px;}
  .search .searchbar input {width: 100%;}
  

}
@media(max-width:1349px){
  .mensswwwsss{padding-right: 15px!important;padding-left: 15px!important;}
}
@media(min-width:768px) and (max-width:991px){
.members .base-btn1 {padding: 0 17px;}
.coverDesign2tabqqq2 img {height: 147px;max-width: 107px;}
.coverDesign2tabqqq img {height: 127px;margin-bottom: 27px;}
.login-1 .lll .book-wrapper2 img {max-width: 107px;height: 147px;}
.ttt6 .base-btn1 {font-weight: 400;padding: 0px 10px;line-height: 43px;text-transform: capitalize;}
.ttt5 .videosss2 {width: 230px!important;height: 197px!important;}
.zzz8:before {top: 27px;left: -8px;}
.bookadspreview .lll h1 {font-size: 23px;}

.res5c {margin: 3px auto;}
.res5c {background-color: #0ca9f3!important;cursor: pointer;}
.menuq2 {width: 100%;margin: 7px auto;padding: 17px;border-top: 0px solid #ff1100; box-shadow: 1px 1px 7px #cccccc;}
.menuq2 .sub-menu {width: 90%; margin: 17px auto;padding: 17px;border-left: 4px solid #795548;box-shadow: 1px 1px 7px #cccccc;display: block;float: left;}
.text_shadow {display: none;}
.rwt__tablist[aria-orientation="vertical"] {margin-right: 2rem!important;}
.topslider65 .col-md-1{display: none;}
.topslider65 .col-md-3 {padding: 0;}
.ttt .col-md-12 {padding: 0;}
.pagebooks2 .col-md-4 {padding-left: 0;padding-right:7px;}
.pagebooks2 .col-md-8 {padding-left: 0;}
.ttt2 {padding: 37px 6px 27px 6px;}
.container, .container-md, .container-sm {max-width: 767px;}
.bookads2 .lll h1 {font-size: 23px;}
.lll h1 {font-size: 23px;}


.coverDesign img {height: 237px;margin: 37px auto 0px;}
.text_shadow2 {display: none;}  
.text_shadow2s {display: none;}  
.text_shadowbook2 {display: none;}  
.text_shadowbook2s {display: none;}  
.pagebooks .text_shadowbook2 {display: none;}  
.pagebooks .text_shadowbook2s {display: none;}  
.topslider62 h1 {z-index: 7;}
.topslider66 h1 {z-index: 7;}
.coverDesign2 img {height: 170px;margin: 17px auto 17px;}
.base-btn2 {right: 7%;bottom: 0%;}
.text_shadow3 {display: none;} 
.text_shadow5 {display: none;} 
.topslider63 h1 {line-height: 47px;font-size: 27px; margin-bottom: 37px;}
.topslider63 {padding: 60px 0px 60px 0px;margin: 47px 0 0 0;}
.topslider64 h1 {margin-top: 0;}
.topslider64 {padding: 67px 0px 67px 0px;}
.videosss2 {width: 324px;height: 217px;margin: 37px auto 0px;}
.base-btn4 { bottom: -33%;}
.topslider65 {padding: 47px 0px 77px 0px;margin-top: 0px;}
.sub-menu .menu-item a{margin-bottom: 10px;}
    
    
.leqsss {height: 237px;margin: 47px auto 37px;}
.zzz8s {width: 107px;height: 107px;}
.zzz7 {margin-left: 0;}
.zzz8 {margin-top: 0;}
.FGFUF {padding: 17px;}
.bookadspreview {padding: 37px 0 37px 0;}
.logsss3 {padding: 37px 15px 37px 15px;margin-top: 0;margin-bottom: 60px;}
.login-1 {padding: 37px 0 37px 0;}
.yyy2 {margin-bottom: 0px;font-size: 27px;}
.xxx {line-height: 47px;margin-bottom: 37px;font-size: 27px;}
.bookadspreview .addbook {margin-bottom: 60px;}
.yyy iframe {width: 300px;height: 237px;margin: 37px auto;}

.coverDesign5 img {height: 107px;border: 2px solid white;}
.taaa {width: 100%;float: left;background: linear-gradient(180deg,#ffffff 0,#f3f3f3 0,#ffffff 99%);}
.coverDesign6 img { height: 130px;margin: 17px auto 17px;}
.rwt__tabs[data-rwt-vertical="true"] { display: block!important;}
.taaa {padding: 27px;}
.coverDesign2tab img {max-height: 117px;}
.base-btn4s {padding: 0 10px;font: 600 16px/55px "Montserrat", sans-serif;text-transform: capitalize;}
.aaalasssqqq2 {display: none!important;}
.topslider6.Recommended__homes h1 {padding: 10px 0 17px 0!important;}
.Standard__home .bookscovers img {max-width: 114px!important;height: 167px!important;border-radius: 7px!important;}
  .book-wrapper img{max-width: 177px; width: auto;height:257px}
  .bookads2 .col-md-8 {width: 100%; display: block;max-width: 100%;flex: 0 0 100%;margin-top: 17px;}.heading.color-white .header {font-size: 27px;}
  .topslider6 .book-wrapper2 img {margin: 0px auto 7px;max-width: 127px;height: 180px;}
  .ttt .date-published {right: calc(100% - -1.5em);}
  .date-published span.big {font-size: 17px;}
  .ttt6 .booksrec2 img {max-width: 167px;max-height: 97px;}
  .ttt6 .booksrec2 {height: 97px;}
  .ttt6 .aaa3 {padding: 10px;}
  .ttt .post-thumb:before {left: -24px;}
  .aaa6 {width: 100%; display: block;max-width: 100%;flex: 0 0 100%;margin-top: 17px;}
  .memberstabsss li {padding: 10px;}
  .ttt6 h2{font-size: 20px!important;}
  .members {padding: 37px 0 37px 0;}
  .myaccount-content {font-size: 25px;}
  .profileimgsss {width: 127px;min-width: 127px;min-height: 127px;height: 127px;}
  .header-middle img {margin: 0 auto;display: block;}
  .res5c {padding: 6px 10px 6px 10px;margin: 17px auto;display: block!important;float: right;}
  .aaa {display: none;}
  .topslider {padding: 33px 0 32px 0;}
  .topslider4 {padding: 33px 0 47px 0;position: relative;}
  .topslider4 h1 {margin-bottom: 7px;font-size: 33px;}
  .book2 { transform: scale(1.0);}
  .videosss2 {width: 100%!important;height: 167px!important;margin: 17px auto 0px;}
  .topslider6 {padding: 33px 10px 33px 10px;height: auto;}
  .topslider5:before {opacity: 0;}
  .topslider66 {padding: 0px 0px 27px 0px;}
  .ttt7 {padding: 0 17px 0 17px;}
  .menu-itemcopyright {right: 0;top: 20px;}
  .copyright-block .ttt8s{flex: 0 0 33.333333%!important;max-width: 33.333333%!important;}
  .wpb_single_image.vc_align_right {display: none;}
  .menuq2 { width: 90%;margin: 0 auto;padding: 17px;box-shadow: 1px 1px 7px #cccccc;position: absolute; right: 0;top: 70px;z-index: 1000;background-color: white;}
  .menu-item {display: block;clear: both;margin-bottom: 17px;}
  .menuq2 .sub-menu{ width: 90%;margin: 17px auto;padding: 17px;box-shadow: 1px 1px 7px #cccccc;}
  .sub-menu .menu-item a {color: #000000;}
  .addbook h3 {line-height: 37px;margin-bottom: 17px;font-size: 30px;}
  input.form-controlsss {width: 207px!important;}
  .bookads2 .central-meta {padding: 17px;}
  .flag {padding: 10px 17px 12px 17px;}
  .addbook .col-md-7{width: 100%; display: block;max-width: 100%;flex: 0 0 100%;overflow: hidden;} 
  .search  .col-md-11{width: 100%; display: block;max-width: 100%;flex: 0 0 100%;overflow: hidden;} 
  .top_header_area li {display: block!important;}
  .top_header_area .menu-item a {float: left!important;}
  ul.main-menu>li.menu-item>a {padding-top: 7px;padding-bottom: 7px;}
  .addbook .direction-lsss {z-index: 10000;}
  .bg-lines {display: none;}
  .addbook .flag {font-size: 23px;}
  .currency { font-size: 27px;}
  .value {font-size: 27px;}
  .pagebooks2 h2 {margin-bottom: 27px;font-size: 37px;}
  .pagebooks {padding: 37px 0 37px 0;}
  .ttt7 .menu-item { clear: unset; margin-bottom: 7px;}
  .bookads2 {padding: 37px 0 37px 0;}
  .yyy {padding-right: 0;}
  .thm-btn {padding: 7px 10px 7px 10px;font-size: 16px;}
  .listing-duration {max-width: 467px;}
  .ttt img {max-width: 137px;height: 177px;}
  .inner-box .app {margin-bottom: -33px;}
  .zzz8 img {max-width: 143px;}
  .yyy h1 {font-size: 20px;line-height: 30px;}
  .profileimgsssbooks { width: 127px;max-width: 127px;max-height: 127px;min-width: 127px;min-height: 127px;height: 127px;}
  .lll .book-wrapper2 img {max-width: 137px;height: 187px;}
  .lll {padding: 0;}
  .uuu h2 {font-size: 27px;}
  .news-block-one .base-btn1 {padding: 0px 16px;font-size: 16px;font-weight: 400;line-height: 40px;}
  .sfdfgsss img {max-width: 107px;max-height: 107px;margin: 0px auto;}
  .publsssqqq .aaa3 {padding: 10px 7px;}
  .post-thumb {max-width: 100%;padding-left: 17px;padding-right: 10px;}
  .post-thumb:before {left: -10px;}
  .aaa{display: none;}
  .date-published {right: calc(100% - -0.5em);}
  .topslider5 img {max-width: 137px;height: 200px;}
  .post-title {max-width: 100%;}
  .search .nav-tabssss li {padding: 10px;width: 97%;}
  .search .stansss h4 {margin: 0px 0 7px 10px;font-size: 17px;max-height: 100%;line-height: 23px;}
  .search .base-btn1 {padding: 10px 13px;}
  .search .booksrec4 img {max-height: 117px;}
  .search .videosss2 {width: 223px!important; height: 167px!important;}
  .search .booksrec2 img {max-width: 96px;width: 100%;height: 60px;object-fit: contain;}


}


@media (min-width:1500px) {
  .desc2 span{ font-size: 17px!important; }
  .desc23sss span{ font-size: 17px!important; }


}



@media (min-width:1301px) and (max-width:1500px){
  .top_header_area li.qqq {font-size: 15px;float: none;text-align: center;display: block;}
ul.main-menu>li.menu-item>a {font-size: 18px;}
.coverDesign img {height: 257px;margin: 77px auto 0px;}

ul.main-menu>li.menu-item>a {margin-left: 10px;margin-right: 10px;}

.text_shadow {font-size: 667px;width: 137px;}
.topslider6 h1 {font-size: 40px;}
.coverDesign2 img {
  height: 357px;
  max-width: 237px;
  margin: 27px auto 27px;
}
.base-btn2 {right: 14%;}
.topslider63 h1{z-index: 7;}
.videosss2 {height: 207px;}
.coverDesign5 img {height: 180px;}
.FGFUF {margin-top: 27px;}
.coverDesign6 img { max-width: 137px;height: 197px;}
.ttt7 .menu-item a {font-size: 18px;}
.menu-item a {font-size: 15px;}

}



@media (min-width:1200px) and (max-width:1300px){
  .header-middle img {max-width: 213px;}
  ul.main-menu>li.menu-item>a { margin-left: 10px!important;margin-right: 3px!important;}
  ul.main-menu>li.menu-item:first-child a {margin-left: 0px!important;} 
  ul.main-menu>li.menu-item:last-child a{ margin-right: 0px!important;}
  .ttt7 .menu-item a {font-size: 17px;}
  .direction-r {width: 188px;}
  .direction-l { width: 187px;}
  .ttt3 img {max-width: 217px;height: 217px;}
  .top_header_area .container{max-width: unset;}
  .text_shadow {font-size: 892px;width: 147px;}
  .topslider63 h1 {z-index: 7;}
  .base-btn4s {padding: 0 37px;text-transform: capitalize;}
  .coverDesign5 img {height: 147px;}
  .FGFUF {padding: 37px;margin-top: 37px;}
  .coverDesign6 img {height: 187px;}
  .coverDesign2tab img {max-height: 197px;}
  .top_header_area li.qqq {font-size: 14px;float: right;}
  input.form-controlsss {width: 367px!important;}
  ul.main-menu>li.menu-item>a {font-size: 16px;}
  .pagebooks2 .col-md-1{padding:0;}
  .pagebooks2 .col-md-8{padding-left:0;}
  .ttt img {height: 207px;}
  .lower-content h3 {line-height: 20px;font-size: 15px;}
  .pagebooks .text_shadowbook2s {left: 77px;}
  .bookadspreview .text_shadowbook2s {left: 77px;}
  .ttt5 .videosss2 {width: 337px;}
 
  .lower-content h3 {line-height: 23px;font-size: 15px;margin-top: 6px;}
  .ttt6 .aaa3 {padding: 10px;}
 
  .aaa3 .desc {margin-bottom: 17px;}
  .videosss2 {height: 157px;}

}
@media (min-width:900px) and (max-width:991px){
  .booksrec4 img {max-height: 137px!important}
}
@media (min-width:992px) and (max-width:1199px){
  .aaalasssqqq img {width: 47px!important;height: 67px!important;}
  .search .booksrec4 img {max-height: 137px;}
  .Standard__home .bookscovers img {max-width: 147px!important;height: 207px!important;}
  input.form-controlsss {width: 287px!important;}
  .search .booksrec2 img {width: 93px;height: 93px;}
  ul.main-menu>li.menu-item>a {margin-left: 6px!important;margin-right: 0px!important;font-size: 15px;}
  ul.main-menu>li.menu-item:first-child a {margin-left: 0px!important;} 
  ul.main-menu>li.menu-item:last-child a{ margin-right: 0px!important;}
  .ttt7 .menu-item a {font-size: 16px;}
  .has-children .svg-inline--fa.fa-w-14 {display: none;}
  ul.main-menu>li .sub-menu .menu-item a {font-size: 16px;}
  .ttt7 .menu-item {margin-right: 7px;}
  .book-wrapper img{max-width: 172px;height: 257px;}
  .booksrec4 img {max-height: 180px;}
  .sfdfgsss img {max-width: 147px;}
  .topslider5:before {top: -27px;}
  .ttt5 .videosss2 { width: 277px;height: 200px;}
  .addbook .direction-lsss {left: -30%;}
  .zzz8 img {max-width: 177px;}
  .lll .book-wrapper2 img {max-width: 117px;height: 147px;}
  .lll{padding: 0;}
  .search .stansss h4 {margin: 7px 0 10px 10px;font-size: 17px;line-height: 23px;}
  .Standard__home__sec {padding: 17px;}
  .header-middle img {max-width: 137px;padding: 10px 0 10px 0;}
  .top_header_area li.qqq {font-size: 13px;margin-left: 0;line-height: 20px;position: absolute;max-width: 90%;
    top: -8px;}
  .menu-item a {font-size: 16px;}
  .coverDesign2 img {height: 197px;margin: 27px auto 17px;}
  .base-btn2 {right: 20%;}
  .topslider63 h1 {font-size: 37px; margin-bottom: 37px;}
  
  .videosss2 {height: 147px;}
  .coverDesign5 img {height: 120px;}
  .FGFUF {padding: 37px;}
  .topslider65 h1 {font-size: 33px;margin-bottom: 17px;}
  .text_shadow5 {font-size: 607px;}
  .coverDesign6 img {height: 147px;}
  .topslider6 h1 {font-size: 37px;}
  .coverDesign img {height: 223px;float: right;margin-right: 17px;}
  .base-btn4s {font-size: 16px;padding: 0px 27px;font: 600 15px/55px "Montserrat", sans-serif;text-transform: capitalize;}
  .coverDesign2tab img {border: 2px solid white;max-height: 163px;margin-bottom: 27px;}
  .login-1 {padding: 37px 0 0px 0;}
  .breadcrumbs .content-box h1 {font-size: 27px;}
  .ttt .col-md-12{padding:0}
  .book-wrapper img {max-width: 137px; height: 217px;}
  .lll h1 {font-size: 27px;}
  .pagebooks2 .tt6 h2 {font-size: 16px;line-height: 20px;margin-top: 17px;}
  .news-block-one .base-btn1 { font-size: 15px;font-weight: 600;padding: 0px 20px;}
  .lower-content {height: 76px;padding-top: 0px;overflow: hidden;}
  .lower-content h3 {line-height: 23px;font-size: 15px;margin-top: 6px;}
  .leqsss {height: 183px;}
  .ttt6 .aaa3 {padding: 10px;}
  .ttt6 .base-btn1 {font-size: 17px;font-weight: 400;padding: 7px 13px;text-transform: capitalize;}
  .aaa3 .desc {margin-bottom: 17px;}
  .topslider63 h1 {z-index: 7;}
}
@media (max-width: 1199px) and (min-width: 1100px){ 
  ul.main-menu>li.menu-item>a {font-size: 15px!important;}

}

.Standard__home .bookscovers img {
  max-width: 227px;
  height: 300px;
  text-align: center;
  margin: 0 auto;
  display: block;
  box-shadow: -4px 24px 39px -17px rgb(209 216 219);
  border-radius: 17px;
}
.Standard__home h2 {
  font-size: 18px;
  text-align: center;
  margin: 17px 0px 17px 0px;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  font-weight: 600;
}
.Standard__home h6.Price {
  font-size: 18px;
  text-align: center;
  color: #f44336;
  font-weight: 700;
  margin-bottom: 7px;
}
.Standard__home__content {
  height: 177px;
  overflow: hidden;
  font-size: 17px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-bottom: 0;
  font-style: normal;
  background-color: rgb(255 255 255/0)!important;
  text-align: center;
}
.Standard__home__content p, .Standard__home__content span {
  background-color: rgb(255 255 255/0)!important;
  text-align: center;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.Featured__home .bookscovers img {
  max-width: 207px;
  height: 247px;
  text-align: center;
  object-fit: contain;
  margin: 0 auto;
  display: block;
  border-radius: 17px;
}
.Featured__home h2 {
  font-size: 18px;
  text-align: left;
  margin: 17px 0px 17px 0px;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  font-weight: 600;
}
.Featured__home h6.Price {
  font-size: 18px;
  text-align: left;
  color: #f44336;
  font-weight: 700;
  margin-bottom: 7px;
}
.Featured__home .bookscovers {
  max-width: 260px;
  float: left;
  margin-right: 27px;
}
.Featured__home__content {
  height: 187px;
  overflow: hidden;
  font-size: 17px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-top: 17px;
  font-style: normal;
  background-color: rgb(255 255 255/0)!important;
  text-align: left;
}
.Featured__home__content p, .Featured__home__content span {
  background-color: rgb(255 255 255/0)!important;
  text-align: left!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.Video__home .videosss2 {
  height: 287px;
}
.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 0px solid #ddd!important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Highlighted__homes h2 {
  font-size: 18px;
  text-align: center;
  margin: 17px 0px 17px 0px;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  font-weight: 600;
}
.Highlighted__homes h6.Price {
  font-size: 18px;
  text-align: center;
  color: #f44336;
  font-weight: 700;
  margin-bottom: 7px;
}
.Highlighted__homes__content {
  height: 177px;
  overflow: hidden;
  font-size: 17px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-bottom: 0;
  font-style: normal;
  background-color: rgb(255 255 255/0)!important;
  text-align: center;
  margin-bottom: 37px;
}
.Highlighted__homes__content p, .Highlighted__homes__content span {
  background-color: rgb(255 255 255/0)!important;
  text-align: center!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.Recommended__homes .Recommended__homes__cover img {
  height: 197px;
  display: block;
  border-radius: 7px;
  border-radius: 7;
  box-shadow: -1px 1px 10px #807f7f;
  width: 147px;
  object-fit: cover;
  object-position: center top;
}
.Recommended__homes__Lefts {
  width: 100%;
  float: left;
  border: 0 solid #e6e6fa;
  box-shadow: 3px 5px 10px 0px #e5e9eb;
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
  padding: 27px 17px;
  background: linear-gradient(to right, #ffffff, #eff9ff);
}
.Recommended__homes__Lefts h2 {
  font-size: 18px;
  font-weight: 600;
  max-height: 54px;
  overflow: hidden;
  line-height: 27px;
}
.Recommended__homes__centers{
  margin: 0 15px 34px;
  width: 100%;
  float: left;
  padding: 37px 27px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  box-shadow: 8px 8px 30px 0 rgb(42 67 113 / 15%);
  -webkit-box-shadow: 8px 8px 30px 0 rgb(42 67 113 / 15%);
  -moz-box-shadow: 8px 8px 30px 0 rgba(42,67,113,.15);
  color: #fff;
  background-color: #fff;
}
.Recommended__homes__centers h6.Price {
  font-size: 18px;
  color: #f44336;
  font-weight: 700;
  margin-bottom: 14px;
}
.Recommended__homes__centers a h2{
  font-weight: 600;
}
.Recommended__homes__content{
  max-height: 360px;
  overflow: hidden;
  font-size: 17px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-bottom: 0;
  font-style: normal;
  background-color: rgb(255 255 255/0)!important;
  text-align: left;
  margin-top: 17px;
}
.Recommended__homes__content p, .Recommended__homes__content span {
  background-color: rgb(255 255 255/0)!important;
  text-align: left!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.Latest__Homes h2 {
  font-size: 18px;
  text-align: center;
  margin: 7px 0px 10px 0px;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  font-weight: 600;
}
.Latest__Homes h6.Price {
  font-size: 18px;
  text-align: center;
  color: #f44336;
  font-weight: 700;
  margin-bottom: 7px;
}
.Latest__Homes__content {
  height: 96px;
  overflow: hidden;
  font-size: 17px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  font-style: normal;
  background-color: rgb(255 255 255/0)!important;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 13px;
}
.Latest__Homes__content p, .Latest__Homes__content span {
  background-color: rgb(255 255 255/0)!important;
  text-align: center!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.Latest__Homes__audio {
  padding: 17px;
  box-shadow: 1px 30px 60px 8px rgb(70 72 228 / 7%);
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
  margin-bottom: 37px;
  background-color: white;
}
.Featured__home__sec {
  border: 2px dashed #dbeeff;
  width: 100%;
  float: left;
  margin-bottom: 37px;
  padding: 27px;
  background-color: white;
  border-radius: 7px;
}
.Standard__home__sec {
  border: 2px dashed #dbeeff;
  width: 100%;
  float: left;
  margin-bottom: 37px;
  padding: 27px;
}
.Highlighted__homes__sec {
  width: 100%;
  float: left;
  padding: 10px;
}
.forms__nesss {
  margin: 0 auto;
}
input.form-controlsss {
  border-radius: 5px 0 0 5px;
  padding: 14px 20px;
  position: relative;
  border: 1px solid #e6e6fa;
  width: 447px;
}
.copyright-block img {
  margin-right: 60px;
}
.fotter__btnsss {
  border-radius: 0 5px 5px 0;
  padding: 14px 26px 14px 27px;
  margin: 0;
  color: #fff;
  background-color: #2eb6f6;
  border: 0;
  background-image: linear-gradient(to bottom right,#87daff 0,#0ca9f3)!important;
  font-size: 18px;
}
.avaterimggg {
  box-shadow: 0px 1px 11px #eaeff5;
  float: left;
  width: 77px;
  height: 77px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  border-radius: 100%;
  display: block;
}
.publishers__content {
  height: 177px;
  overflow: hidden;
  font-size: 17px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-bottom: 0;
  font-style: normal;
  background-color: rgb(255 255 255/0)!important;
  text-align: center;
  margin-bottom: 37px;
}
.publishers__content p, .publishers__content span {
  background-color: rgb(255 255 255/0)!important;
  text-align: center!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.lower-content-homes {
  margin-bottom: 10px;
  height: 87px;
  overflow: hidden;
}
.lower-content-homes h6 {
  color: #ff1100;
  font-size: 16px;
}
.lower-content-homes h3, .lower-content-homes h6 {
  font-weight: 400;
  font-family: "Lato",sans-serif;
  text-align: center;
}
.lower-content-homes h3 {
  position: relative;
  color: #000000;
  line-height: 27px;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  text-align: center;
}

.Publisher__home__content {
  height: 187px;
  overflow: hidden;
  font-size: 17px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-top: 17px;
  font-style: normal;
  background-color: rgb(255 255 255/0)!important;
  text-align: center;
  margin-bottom: 17px;
}
.Publisher__home__content p, .Publisher__home__content span {
  background-color: rgb(255 255 255/0)!important;
  text-align: center!important;
  font-size: 17px!important;
  line-height: 30px!important;
  color: #444!important;
  font-family: "Lato",sans-serif!important;
}
.Featured__home__base {
  background-image: linear-gradient(to bottom right,#5dceff 0,#0a92d3)!important;
  float: right;
  padding: 10px 22px;
  line-height: normal;
  border-radius: 7px;
  color:white;
}
.mensswwwsss {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.Recommended__cols {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  align-items: center;
  flex-direction: row;
}
.Recommended__colrights {
  margin: 0px;
}
p.success__footersss {
  color: #089f08;
  font-size: 20px;
  margin-bottom: 7px;
}
.aaalasssqqq{
  padding:0px;
  display: flex;
}
.aaalasssqqq2 {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
}
.aaalasssqqq img {
  width: 60px;
  height: 84px;
  border-radius: 3px;
  box-shadow: 0 7px 7px -5px, 0 0 7px -6px;
  color: #a9a1a1;
  margin: 0 auto;
  display: block;
}




